<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>The Witcher <span>Book Cover Series</span></h1>
                    <h1>The Witcher <span>Book Cover Series</span></h1>
                </div>
            </div>
        </div>
        <h2>Book Cover Design | Print | Original Artwork</h2>

        <div class="portfolio-cont">

            <div class="p-content-row-2">
                <div>
                    <p>To design this series of book covers, it was necessary to begin with original artwork in a consistent style. The colors were chosen as complementary pairs and the typography 
                    echoes the northern-European character of the setting. The rest of the jackets contain additional Celtic elements and are covered with information aboutt he series.</p>
                </div>
                <div>
                    
                </div>
            </div>
        
            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="all three books with covers" src="../assets/images/portfolio/wt-family.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="three book spines lined up" src="../assets/images/portfolio/wt-spines-res.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="a look inside the first book jacket" src="../assets/images/portfolio/wt-inside-res.jpg">
                </figure>
            </div>

            

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioWitcher",

}
</script>

<style lang="scss">

</style>